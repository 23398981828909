import React from 'react';
import {SEO} from '../components/seo';
import {Layout} from '../components/layout';
import NotFoundBox from '../components/not-found-box';
import {useIntl} from 'gatsby-plugin-react-intl'

const NotFoundPage = () => {
    const intl = useIntl().formatMessage;

    return (
        <Layout bodyClass="fourZeroFourPage">
            <SEO title="404: Not found" />
            <main>
                <NotFoundBox
                    title={intl({ id: 'notFoundPage.title' })}
                    message={intl({ id: 'notFoundPage.message' })}
                    button={intl({ id: 'notFoundPage.button' })}
                />
            </main>
        </Layout>
    )

};

export default NotFoundPage;
