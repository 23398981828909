import React from 'react';
import {Link} from 'gatsby';
import PropTypes from "prop-types";

const NotFoundBox = ({
            title,
            message,
            button
 }) => {
    return (
        <div className="not-found">
            <h1
                className="not-found__title">
                {title}
            </h1>
            <h2
                className="not-found__subtitle">
                {message}
            </h2>
            <Link
                to="/"
                className="not-found__link">
                {button}
            </Link>
        </div>
    )
};

export default NotFoundBox;

NotFoundBox.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired
}
